import React from 'react'
import { css } from '@emotion/react'
import PageTitle from 'utils/PageTitle'
import { Subtitle, Body2 , Big } from 'styles/FontStyles'
import { Container } from 'styles/CssHelpers'

export default function AvisoPrivacidad() {
  return (
    <>
      <PageTitle>Aviso de Privacidad</PageTitle>
      <Container slim css={css`padding-top:10rem;`}>
        <Big>Aviso de Privacidad</Big>
        <hr/>
        <Subtitle>Finalidades</Subtitle>
        <p>El Instituto de Inglés Bristol A.C. conocido con su nombre comercial como Bristol Inglés Profesional es una institución educativa privada especializada en la enseñanza del idioma inglés a niños, jóvenes y adultos y cuyos estudios cuentan con el Reconocimiento de Validez Oficial (RVOE) otorgado por la Secretaría de Educación Pública. Nuestra institución se encuentra ubicada en Morelos 35 Col. Centro, CP 91500 en Coatepec, Veracruz y Av. Orizaba 94 bis Col. Obrero Campesina CP 91020 en Xalapa, Veracruz. Bristol Inglés Profesional, en lo sucesivo “El Colegio” conciente de la importancia que significa la información relacionada con los datos personales y familiares que nos proporcionan los alumnos y en cumplimiento con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, nos comprometemos a salvaguardar dicha información estableciendo lineamientos y procedimientos internos que aseguren la integridad de la información y su manejo confidencial. El presente Aviso de Privacidad se da a conocer con la finalidad de dar cumplimiento a lo establecido en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares en lo sucesivo “Ley de Protección de Datos”, publicada en el Diario Oficial de la Federación el día 5 de julio del año 2010. Dicho ordenamiento tiene la finalidad de regular el tratamiento que se da a los datos personales de los alumnos a efecto de garantizar la privacidad y el derecho a la autodeterminación informativa de las personas.</p>
        <Subtitle>Medios de Obtención de los Datos Personales</Subtitle>
        <p>Los medios para recolectar datos personales de los alumnos, aspirantes, padre o tutor, es a través de las personas encargadas de la atención al público en Recepción, quienes son los responsables de recabar la información personal en forma directa al momento de formular su inscripción a través de la solicitud de ingreso al Colegio o a través de nuestro sitio de Internet. Siendo éstos los únicos medios de obtención de datos personales.</p>
        <Subtitle>Responsables del tratamiento de sus datos personales</Subtitle>
        <p>El Departamento de Administración así como el Departamento de Promoción Institucional son los responsables del tratamiento de sus datos personales y se les puede localizar en las instalaciones de “El Colegio” arriba mencionados. Asimismo, ponemos a su disposición los números telefónicos (228) 816 0543 y 840 5791, así como el correo electrónico: informes@bristolingles.com.mx o en nuestro sitio web: www.bristolingles.com.mx</p>
        <Subtitle>Transferencia de Datos Personales</Subtitle>
        <p>Se refiere a cualquier tipo de comunicación de datos personales realizada a una persona física o moral distinta de “El Colegio”: La transferencia de datos sólo podrá darse en los siguientes casos: • Aquellos derivados de disposiciones legales. Tal es el caso de la información que solicita la Secretaria de Educación Pública El tercero receptor de la información asumirá las mismas obligaciones que correspondan al responsable que transfirió los datos. El responsable no venderá, regalará, facilitará ni alquilará la información a terceros y se compromete a no transferir los datos personales, salvo en los casos mencionados anteriormente y los casos establecidos en la “Ley de Protección de Datos”. Si Usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.</p>
        <Subtitle>Datos Personales que se Recaban</Subtitle>
        <p>Los datos personales que recabamos tienen como finalidad conocer los datos generales de los alumnos y el medio ambiente de procedencia. Para ello, “El Colegio” requiere recabara los siguientes datos personales de los alumnos: • Datos de Identificación del alumno: Nombre completo, edad, fecha de nacimiento, nacionalidad, domicilio, CURP, teléfono, correo electrónico. • Datos sobre Padre o Tutor: Datos Personales (nombre completo, domicilio, teléfono, correo electrónico), así como información fiscal en caso de requerir facturación electrónica. Estos datos personales serán recabados en forma directa cuando usted mismo nos los proporcione al momento de su ingreso al Colegio.</p>
        <Subtitle>Tratamiento de los Datos Personales</Subtitle>
        <p>Los datos personales a que se refiere el apartado anterior serán utilizados por “El Colegio” para las siguiente finalidades : • Proveer los servicio educativos que ha solicitado. • Incorporar la información en su expediente académico. • Brindar servicios de emergencia (en caso necesario). • Cumplir con los requerimientos de información solicitados por las autoridades educativas (SEP), de conformidad con los artículos 12, fracción X y 22 de Ley General de Educación y con el Programa de Simplificación Administrativa Escolar, la Secretaría de Educación Pública exige al plantel educativo recabar datos personales e información de los alumnos que ingresan a “El Colegio”. • Como información de contacto para localizar a padres o tutores. • Para la formulación de facturación electrónica, cuando así lo solicite, conforme a las disposiciones fiscales en vigor. • Para fines publicitarios, mercadotécnicos e informativos.</p>
        <Subtitle>Derecho ARCO</Subtitle>
        <p>Si desea tener Acceso a su información o llevar a cabo Rectificaciones, Cancelaciones y Oposición con respecto a sus datos personales o del alumno, en ejercicio a su Derecho ARCO, deberá dirigir su solicitud por escrito a “El Colegio”, siendo ésta la única vía establecida por el responsable para llevar a cabo dichas modificaciones. Asimismo, le informamos que usted podrá ejercer en todo momento sus derechos de acceso, rectificación, cancelación y oposición o revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales a fin de que dejemos de hacer uso de los mismos, dirigiendo su solicitud por escrito a la Dirección General. “El Colegio” tendrá un plazo máximo de 5 días para atender su petición informándole por escrito sobre la procedencia de la misma. Le informamos que si usted no está de acuerdo con la respuesta que le demos a su solicitud que limite el uso o divulgación de datos, usted tiene el derecho de iniciar un procedimiento de protección de datos ante el Instituto Federal de Acceso a la Información y Protección de datos (IFAI) dentro de los 15 días siguientes a la fecha en que reciba la respuesta de “El Colegio” o a partir de que concluya el plazo de 20 días contados a partir de la fecha de recepción de su solicitud de ejercicio de derechos.</p>
        <Subtitle>Cambios en el Aviso de Privacidad</Subtitle>
        <p>“El Colegio” se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad . Cualquier modificación al presente aviso le será notificada a través de nuestro sitio web: www.bristolingles.com.mx, sección Aviso de Privacidad, o por escrito mediante Circular Interna.</p>
        <Body2>Fecha de última actualización: 13 marzo de 2013</Body2>
      </Container>
    </>
  )
}
